import {
  AccountData,
  ChainInfo,
  Keplr,
  OfflineDirectSigner,
} from '@keplr-wallet/types';
import chains from 'components/Wallet/cosmos/chains';
import { CosmosChainId } from 'types/emoney/Chain/indexV2';
import Provider, { SignMessageResponse } from './abstract';

function uint8ArrayToHex(uint8Array: Uint8Array): string {
  return Buffer.from(uint8Array).toString('hex');
}

class CosmosProvider extends Provider {
  isInstalled = false;

  isEnabled = false;

  private provider: Keplr;

  private signer: OfflineDirectSigner;

  protected chainId: CosmosChainId;

  constructor({ chainId }: { chainId: CosmosChainId }) {
    super({ chainId });
    this.chainId = chainId;
  }

  async enable(): Promise<void> {
    this.getProvider();
    this.getSigner();

    await this.suggestChain(this.chainId);

    this.isEnabled = true;
  }

  async disconnect(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async getAddresses(): Promise<string[]> {
    if (this.signer) {
      const accounts: readonly AccountData[] = await this.signer?.getAccounts();

      const addresses = accounts.map((account) => account.address);
      return addresses;
    }
    return [];
  }

  async getChain(): Promise<string> {
    throw new Error('Method not implemented.');
  }

  async signMessage(message: string): Promise<SignMessageResponse> {
    const address = (await this.getAddresses())[0];
    const sig = await this.provider?.signArbitrary(
      this.chainId,
      address,
      message,
    );

    // Verify signature
    if (sig?.signature && sig?.pub_key?.value) {
      const signatureArray = new Uint8Array(
        Buffer.from(sig?.signature, 'base64'),
      );
      const publicKeyArray = new Uint8Array(
        Buffer.from(sig.pub_key.value, 'base64'),
      );

      const signature = `0x${uint8ArrayToHex(signatureArray)}`;
      const publicKey = `0x${uint8ArrayToHex(publicKeyArray)}`;
      return {
        signature,
        publicKey,
      };
    }
    throw new Error('unable to produce signature in Keplr');
  }

  async suggestChain(chainId: string): Promise<void> {
    try {
      const chainInfo = chains.find((c) => c.chainId === chainId) as ChainInfo;

      await this.provider?.experimentalSuggestChain(chainInfo);
    } catch (error) {
      console.error('Could not set chain:', chainId, error);
    }
  }

  getProvider(): Keplr {
    // Return the Cosmos provider instance
    if (!this.provider) {
      const { keplr } = window;
      if (keplr) {
        this.isInstalled = true;
        this.provider = keplr;
      }
    }
    return this.provider;
  }

  getSigner(): OfflineDirectSigner {
    if (!this.signer) {
      this.signer = this.provider?.getOfflineSigner(
        this.chainId,
      ) as OfflineDirectSigner;
    }
    return this.signer;
  }
}
export default CosmosProvider;
