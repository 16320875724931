'use client';

import React from 'react';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Config, State, WagmiProvider } from 'wagmi';
import CosmosProvider from 'components/Wallet/ContextProvider/CosmosContextProvider';
import Disclaimer from './RainbowKit/Disclaimer';
import { getRainbowKitCustomTheme } from './RainbowKit/theme';
import { WalletProvider } from './WalletProvider';

const queryClient = new QueryClient();

const WalletConnector = ({
  config,
  initialState,
  children,
}: {
  children: React.ReactNode;
  initialState?: State;
  config: Config;
}) => {
  return (
    <WagmiProvider config={config} initialState={initialState} reconnectOnMount>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          theme={getRainbowKitCustomTheme()}
          modalSize="wide"
          appInfo={{
            appName: 'Monerium',
            learnMoreUrl: 'https://monerium.com',
            disclaimer: Disclaimer,
          }}
        >
          <CosmosProvider>
            <WalletProvider>{children}</WalletProvider>
          </CosmosProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
export default WalletConnector;
