import React, { useContext } from 'react';
import { ParsedQuery } from 'query-string';
import type { CurrencyCode } from 'types/emoney/Token';
import { AppConfig } from './types';

interface AppContext extends AppConfig {
  pathname: string;
  query?: ParsedQuery;
  supportedCurrency: CurrencyCode[];
}

export const init: AppContext = {
  apiUrl: '',
  environment: 'dev',
  isInviteCodeDisabled: false,
  isKycRequired: false,
  isSandbox: false,
  isProviderOptionForNewAccounts: false,
  isMaintenance: false,
  isAppsEnabled: false,
  // supportedCurrency: ['eur', 'gdp', 'usd', 'isk'],
  supportedCurrency: [],
  wsUrl: '',
  pathname: '',
  isProxy: false,
  isFeatureUploadFilesToHelpThread: false,
  isFeatureCrossChainSend: false,
  isFeatureFXServices: false,
  isFeatureLinkWalletSimple: false,
  kycVerifiers: ['onfido', 'monerium'],
  isAppPreviewEnabled: false,
  canPlaceGbpOrder: [],
  chains: [],
};

const AppContext = React.createContext<AppContext>(init);

export const useAppContext = () => useContext(AppContext);

export default AppContext;
