import chains from 'components/Chain/chainsV2';
import { Environment } from 'types/app';
import { Chain, ChainUniqueName, EVMChainId } from 'types/emoney/Chain/indexV2';
import evmChains, { localhostEvmChains } from './evm/chains';

/**
 * Filter our chains definitions by supported chainNames for the current environment.
 */
export const getSupportedChains = (
  supportedChainNames?: ChainUniqueName[],
): Chain[] => {
  if (!supportedChainNames?.length) {
    throw new Error(
      'No blockchain configurations provided. Please ensure the CHAINS environment variable is set correctly.',
    );
  }

  return chains.filter((c) => supportedChainNames?.includes(c.id));
};

/**
 * Filters EVM chains by supported chainNames for the current environment.
 */
export const getSupportedEvmChains = (
  supportedChainNames?: ChainUniqueName[],
  env?: Environment,
) => {
  const supported = getSupportedChains(supportedChainNames);

  const chainIds = new Set(
    supported.map((chain) => chain.chainId),
  ) as Set<EVMChainId>;

  if (env === 'dev') {
    return localhostEvmChains.filter((evmChain) =>
      chainIds.has(evmChain.id as EVMChainId),
    );
  }

  return evmChains.filter((evmChain) =>
    chainIds.has(evmChain.id as EVMChainId),
  );
};
