export interface SignMessageResponse {
  signature: string;
  publicKey: string;
}

abstract class Provider {
  protected chainId: string;

  constructor({ chainId }: { chainId: string }) {
    this.chainId = chainId;
  }

  abstract enable(): Promise<void>;

  abstract disconnect(): Promise<void>;

  abstract getAddresses(): Promise<string[]>;

  abstract getChain(): Promise<string>;

  abstract signMessage(message: string): Promise<SignMessageResponse>;

  abstract suggestChain(chainId: string): Promise<void>;

  abstract getProvider(): any;
}
export default Provider;
