import React, { FC, useMemo } from 'react';
import StyleContext from 'isomorphic-style-loader-react18/StyleContext';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import { cookieToInitialState } from 'wagmi';
import { ChainsProvider } from 'components/Chain/Provider';
import { config as getWagmiConfig } from 'customer/components/emoney/Wallet/config';
import { ChainUniqueName } from 'types/emoney/Chain/indexV2';
import TokenProvider from './components/emoney/Token/Provider';
import WalletConnector from './components/emoney/Wallet/WalletConnector';
import AppContext from './context';
import { AppConfig } from './types';

const App: FC<{
  pathname: string;
  query?: Record<string, string>;
  config: AppConfig;
  insertCss: (...styles: IsomorphicStyleLoaderStyle[]) => void;
  children: React.ReactNode;
  cookies: string;
}> = ({ pathname, query, config, insertCss, children, cookies }) => {
  const appCtx = useMemo(
    () => ({ pathname, query, insertCss, ...config }),
    [pathname, query, config],
  );
  const styleCtx = useMemo(() => ({ insertCss }), [insertCss]);

  const wagmiConfig = getWagmiConfig(
    config.environment,
    appCtx.chains as ChainUniqueName[],
  );
  const wagmiInitialState = cookieToInitialState(wagmiConfig, cookies);

  return (
    <StyleContext.Provider value={styleCtx}>
      <AppContext.Provider value={appCtx}>
        <TokenProvider>
          <ChainsProvider supportedChains={appCtx.chains}>
            <WalletConnector
              config={wagmiConfig}
              initialState={wagmiInitialState}
            >
              <SnackbarProvider maxSnack={3}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  {children}
                </LocalizationProvider>
              </SnackbarProvider>
            </WalletConnector>
          </ChainsProvider>
        </TokenProvider>
      </AppContext.Provider>
    </StyleContext.Provider>
  );
};

export default App;
