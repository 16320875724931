import { useCallback, useContext, useMemo } from 'react';
import allChains from 'src/components/Chain/chainsV2';
import {
  Chain,
  ChainName,
  EcosystemChainId,
  EcosystemId,
} from 'types/emoney/Chain/indexV2';
import { ChainsContext } from './Provider';
import {
  getAddressUrl,
  getBlockUrl,
  getExplorerTokenByAddressUrl,
  getTokenUrl,
  getTransactionUrl,
} from './util/explorer';

/** Use to find chain and/or chain data */
export const useChains = (kind?: EcosystemId) => {
  const chains = useContext(ChainsContext);
  const filteredChains = useMemo(() => {
    if (kind) {
      return chains.filter((c) => c.kind === kind);
    }
    return chains;
  }, [chains, kind]);

  return filteredChains;
};

export const useChain = (id: ChainName | EcosystemChainId) => {
  const chains = useContext(ChainsContext);

  let chain =
    chains.find((c) => c.chain === id) ||
    (chains.find((c) => c.chainId === id) as Chain);

  if (!chain) {
    /**
     * Fallback
     * If a chain is not found in the list of chains, it is possible that the chain is not supported in the current environment.
     * For example, you launch the app with a production proxy and only CHAINS=polygon.
     * Then we still have to be able to resolve all the ethereum and gnosis instances.
     */
    chain =
      allChains.find((c) => c.chain === id) ||
      (allChains.find((c) => c.chainId === id) as Chain);
  }

  /**
   * Retrieves the default block explorer URL for a given chain in the specified environment.
   *
   * @returns {string} - The URL  of the default block explorer for the specified chain.
   */
  const explorerUrl = useCallback(() => {
    return chain.explorerUrl;
  }, [chain]);

  /**  Constructs the full URL to view an address on the block explorer */
  const explorerAddressUrl = useCallback(
    (address: string) => getAddressUrl(chain, address),
    [chain],
  );

  /** Constructs the full URL for a transaction on the block explorer. */
  const explorerTransactionUrl = useCallback(
    (hash: string) => getTransactionUrl(chain, hash),
    [chain],
  );

  /** Constructs the full URL for a token on the block explorer. */
  const explorerTokenUrl = useCallback(
    (contractAddress: string) => getTokenUrl(chain, contractAddress),
    [chain],
  );

  /** Constructs the full URL for a block on the block explorer. */
  const explorerBlockUrl = useCallback(
    (hash: string) => getBlockUrl(chain, hash),
    [chain],
  );

  /** Constructs the full URL for a token, filtered by address on the block explorer. */
  const explorerTokenByAddressUrl = useCallback(
    (contractAddress: string, address: string) => {
      getExplorerTokenByAddressUrl(chain, contractAddress, address);
    },
    [chain],
  );

  const memoizedValues = useMemo(() => {
    return {
      chain,
      explorerUrl,
      explorerAddressUrl,
      explorerTransactionUrl,
      explorerTokenUrl,
      explorerBlockUrl,
      explorerTokenByAddressUrl,
    };
  }, [id]);

  return memoizedValues;
};
