import React, { createContext, useMemo } from 'react';
import { getSupportedChains } from 'components/Wallet/util';
import { Chain, ChainUniqueName } from 'types/emoney/Chain/indexV2';

export const ChainsContext = createContext<Chain[]>([]);

/**
 * Enrich the list of supported chains with additional data.
 * @param supportedChains - The list of chains to support, passed on from CHAINS, the environment variable.
 * @param children - The children components.
 *
 * @returns List of supported chains and their identifiers.
 */
export const ChainsProvider = ({
  supportedChains,
  children,
}: {
  supportedChains?: ChainUniqueName[];
  children: React.ReactNode;
}) => {
  const chains = getSupportedChains(supportedChains);

  const contextValues = useMemo(() => chains, [supportedChains]);

  return (
    <ChainsContext.Provider value={contextValues}>
      {children}
    </ChainsContext.Provider>
  );
};
