import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useChains } from 'components/Chain/hooks';
import { CosmosChainId } from 'types/emoney/Chain/indexV2'; // Adjust the import path as necessary
import CosmosProvider from '../Provider/CosmosProvider'; // Adjust the import path as necessary

const CosmosProviderContext = createContext<CosmosProvider | undefined>(
  undefined,
);

export const useCosmosProvider = (): CosmosProvider => {
  const context = useContext(CosmosProviderContext);
  if (!context) {
    throw new Error(
      'useCosmosProvider must be used within a CosmosProviderProvider',
    );
  }
  return context;
};

interface CosmosProviderProviderProps {
  children: ReactNode;
}

export const CosmosContextProvider = ({
  children,
}: CosmosProviderProviderProps) => {
  const chains = useChains();
  const nobleChain = chains.find((c) => c.kind === 'cosmos');
  const cosmosProvider = useMemo(() => {
    return new CosmosProvider({
      chainId: nobleChain?.chainId as CosmosChainId,
    });
  }, [nobleChain?.id]);

  return (
    <CosmosProviderContext.Provider value={cosmosProvider}>
      {children}
    </CosmosProviderContext.Provider>
  );
};
export default CosmosContextProvider;
